import type { TMessages } from '@/types/TMessages'

export const messages = {
  cultureSaleRequest: {
    createRequest: 'Создать заявку',
    deliveryBasis: {
      label: 'Базис поставки',
    },
    region: {
      label: 'Регион элеватора',
    },
    elevator: {
      label: 'Название элеватора',
    },
    address: {
      wagon: 'Название ж/д станции',
    },
    volume: 'Объём партии',
  },
  cultureSale: {
    volume: {
      title: 'Объём партии',
      label: 'т',
    },
    topNotice: {
      title: 'Вашу заявку ждут',
      price: 'Среднерыночная цена',
      advice: 'Создайте заявку с ценой в пределах среднерыночной, чтобы получить больше предложений покупателей',
      noPrice: {
        advice: 'Создайте заявку, чтобы покупатели рассмотрели ваше предложение',
      },
      noBuyersNotice: {
        button: 'Жду покупателей в этом регионе',
        title: 'В выбранном регионе ещё нет покупателей для этой культуры',
      },
    },
    onMyTerms: 'Продажа урожая на своих условиях',
    addressNotChosen:
      'Указана текущая цена на выбранную культуру во всех регионах, где есть покупатели. Заполните условия продажи, чтобы рекомендация стала точнее',
    isActualPrice: 'Указана текущая цена на выбранную культуру в вашем регионе',
    isNoActualPrice: 'Указана текущая цена на выбранную культуру в регионах, где недавно состоялись сделки по продаже',
    proteinNotLess: 'Протеин, не менее',
    desiredPriceWithoutVAT: 'Цена (без НДС)',
    desiredPriceWithVAT: 'Цена (с НДС)',
    totalVolume: 'Объём партии',
    buyersAllRegion: 'в {regions} {regionsName}',
    currentPrice: '{price} руб./т',
    minAndmaxPrices: '{minPrice} — {maxPrice} ₽/т',
    oneRegion: 'регионе',
    fromTwoToFourRegions: 'регионах',
    fromFiveToTenRegions: 'регионах',
  },
  workWithVat: 'Работаю с НДС',
  workWithoutVat: 'Работаю без НДС',
  workingWithVATVariant: 'Вариант работы с НДС',
} satisfies TMessages
