import React, { useCallback, useEffect } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { EColor } from '@frontend/pole-ui/lib/common/constants'
import type { IDropdownOption } from '@frontend/pole-ui/lib/components/Dropdown'
import { EIconName, Icon } from '@frontend/pole-ui/lib/components/Icon'
import classnames from 'classnames'
import { HarvestQualityInputs, StoreInput } from '@/components/composed'
import { EHarvestQualityInputsType, EQualityIndicatorsId } from '@/components/composed/HarvestQualityInputs/Enum'
import { openModal } from '@/components/composed/Modal/actions'
import { Button } from '@/components/ui'
import { Dropdown, TextInput } from '@/components/ui/fields'
import { transformOptions } from '@/components/ui/fields/helpers/Dropdown'
import { culturesWithImportantProtein } from '@/constants/outputs'
import { isAuthenticated } from '@/logic/auth/reducer'
import { useData } from '@/logic/data'
import { loadData } from '@/logic/data/actions'
import { setHarvestSaleDeliveryBasis } from '@/logic/harvestSale/actions'
import { pushBtnBannerHarvestGtmEvent } from '@/logic/metrika/harvest/gtmEvents'
import { pushGtmEventOnFocusCultureApplicationFormClick } from '@/logic/metrika/harvest/helpers'
import { getUserGtmInfo } from '@/logic/metrika/helpers'
import { getUserRegion } from '@/logic/userRegion'
import { saleRequestFormVolumeLimits } from '@/pages/CulturesSaleLots/constants'
import type { RootState } from '@/redux/interfaces'
import type { ECultureId } from '@/types'
import type { IRequestQualityIndicators, ISaleRequestByCultureDataResponse } from '@/types/HarvestSaleLots'
import { EDeliveryBasisVariantId } from '@/types/HarvestSaleLots'
import { useEffectAfterMount } from '@/utils/hooks'
import { usePlacementOptions } from '@/utils/hooks/outputs'
import type { ICultureSaleRequestFormProps } from './interfaces'
import { messages } from './messages'

const mapState = (state: RootState) => ({
  isUserAuthenticated: isAuthenticated()(state),
  userGtmInfo: getUserGtmInfo()(state),
  userRegion: getUserRegion()(state),
})

const CultureSaleRequestForm = ({ buyersAndPrices, className, cultureName }: ICultureSaleRequestFormProps) => {
  const { isUserAuthenticated, userGtmInfo, userRegion } = useSelector(mapState)

  const dispatch = useDispatch()

  const { data } = useData<ISaleRequestByCultureDataResponse>('harvestSalePage')
  const { deliveryBasisVariants } = data || {}

  const deliveryBasisOptions: IDropdownOption<string>[] = transformOptions({ options: deliveryBasisVariants, valueKey: 'id' })

  useEffect(() => {
    if (deliveryBasisVariants?.length) {
      dispatch(setHarvestSaleDeliveryBasis(deliveryBasisOptions))
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryBasisVariants])

  const form = useForm()
  const { values } = useFormState()
  const { cultureId, deliveryOptions, regionCode, vatUsed } = values || {}
  const { deliveryBasisId } = deliveryOptions || []
  const isCurrentRegion = values.regionCode || deliveryOptions.storeId

  useEffectAfterMount(() => {
    if (regionCode) {
      dispatch(loadData({ name: 'elevators', shouldLoadForcibly: true, params: { regionCode } }))
    }
  }, [regionCode])

  const { regionOptions, elevatorOptions, isElevatorsLoading, isRegionsLoading, locationOptions } = usePlacementOptions(
    regionCode,
    undefined,
    deliveryBasisId,
  )

  const { data: cultureQualityIndicators } = useData<IRequestQualityIndicators>('harvestSaleQualityIndicators', {
    shouldLoadForcibly: true,

    params: { cultureId },
  })

  const pushGtmEvent = useCallback(() => {
    pushBtnBannerHarvestGtmEvent(
      {
        items: [{ name: 'applicationBanner', id: cultureId, category: cultureId }],
        pagePart: 'content',
        blockName: 'blockOutputsCultureApplicationForm',
        eventCategory: 'interactions',
        eventAction: 'formSend',
        trueEventContext: null,
        eventLabel: 'step_0',
        eventStatus: 'success',
        ecommerceType: 'add',
        eventEcommerce: 'addToCart',
        eventNonInteraction: '0',
        actionField: {
          list: 'listingLotsOutputs',
        },
      },
      userRegion.name,
      userGtmInfo,
    )
  }, [cultureId, userGtmInfo, userRegion.name])

  const { requiredQualityIndicators = [] } = cultureQualityIndicators || {}
  const notRequiredindicators = requiredQualityIndicators.map(el => ({ ...el, required: false }))
  const openBuyersNoticeModal = () => dispatch(openModal({ options: { dialogId: 'NoBuyersModal' } }))

  const isWagonDeliveryBasis = deliveryBasisId === EDeliveryBasisVariantId.wagon
  const shouldChooseStore = deliveryBasisId === EDeliveryBasisVariantId.pickup || isWagonDeliveryBasis

  const shouldChooseElevator =
    deliveryBasisId === EDeliveryBasisVariantId.elevator ||
    deliveryBasisId === EDeliveryBasisVariantId.exw ||
    deliveryBasisId === EDeliveryBasisVariantId.fob

  const gtmFocusField = (fieldName?: string) => {
    return pushGtmEventOnFocusCultureApplicationFormClick(userGtmInfo, userRegion.name, fieldName || '')
  }

  return (
    <div className={classnames('culture-sale-request-form', className)}>
      {cultureName ? (
        <h5 className="cultureName">{cultureName}</h5>
      ) : (
        <div className="space-holder8">
          <h3>{messages.cultureSale.onMyTerms}</h3>
        </div>
      )}
      <div
        className={classnames('culture-sale-request-form__delivery-options', {
          'culture-sale-request-form__delivery-options--elevator': shouldChooseElevator,
        })}
      >
        {/* Базис */}
        <Dropdown
          name="deliveryOptions.deliveryBasisId"
          onOpen={() => gtmFocusField('deliveryOptions.deliveryBasisId')}
          options={deliveryBasisOptions}
          placeholder={messages.cultureSaleRequest.deliveryBasis.label}
        />
        {/* Адрес */}
        {shouldChooseStore && isUserAuthenticated && (
          <StoreInput
            name="deliveryOptions.storeId"
            label={isWagonDeliveryBasis ? messages.cultureSaleRequest.address.wagon : undefined}
            onAddNewLocation={storeId => form.change('deliveryOptions.storeId', storeId.id)}
            options={locationOptions}
            context={isWagonDeliveryBasis ? 'wagon' : undefined}
            position="page"
            onOpen={() => gtmFocusField('deliveryOptions.storeId')}
          />
        )}

        {/* Элеватор */}
        {shouldChooseElevator && (
          <>
            <Dropdown<string>
              containerClassName="regionCode"
              name="regionCode"
              options={regionOptions as IDropdownOption<string>[]}
              placeholder={messages.cultureSaleRequest.region.label}
              onChange={region => form.change('deliveryOptions.regionCode', region)}
              isDisabled={isRegionsLoading}
              isSearchable
              onOpen={() => gtmFocusField('regionCode')}
            />
            <Dropdown
              containerClassName="elevatorId"
              name="deliveryOptions.elevatorId"
              placeholder={messages.cultureSaleRequest.elevator.label}
              options={elevatorOptions}
              isDisabled={isElevatorsLoading || !regionCode}
              isSearchable
              onOpen={() => gtmFocusField('deliveryOptions.elevatorId')}
            />
          </>
        )}
      </div>

      <div className="culture-sale-request-form__details">
        {/* Показатели культуры - только протеин */}
        {culturesWithImportantProtein.includes(cultureId as ECultureId) && (
          <HarvestQualityInputs
            qualityIndicators={notRequiredindicators}
            type={EHarvestQualityInputsType.ADDITIONAL_PARAMS}
            cultureId={values.cultureId}
            customFilter={[
              {
                cultureId,
                include: [EQualityIndicatorsId.PROTEIN_FROM],
              },
            ]}
            onFocusInput={gtmFocusField}
          />
        )}
        {/* Объем партии */}
        <TextInput
          name="volume"
          label={messages.cultureSale.volume.title}
          type="numberFloat"
          isRequiredWithoutSign
          minValue={saleRequestFormVolumeLimits.min}
          maxValue={saleRequestFormVolumeLimits.max}
          numAfterDot={3}
          errorValues={{ numAfterDot: 3 }}
          className="culture-sale-request-form__volume"
          onFocus={() => gtmFocusField('volume')}
        >
          <span>{messages.cultureSale.volume.label}</span>
        </TextInput>
      </div>
      <div className="culture-sale-request-form__details">
        {/* НДС */}
        <Dropdown
          name="vatUsed"
          dropdownModifiers={['black']}
          labelId={messages.workingWithVATVariant}
          options={[
            { value: true, label: messages.workWithVat },
            { value: false, label: messages.workWithoutVat },
          ]}
          onOpen={() => gtmFocusField('vatUsed')}
          isRequired
        />
        {/* Желаемая цена */}
        <TextInput
          name="desiredPrice"
          label={vatUsed ? messages.cultureSale.desiredPriceWithVAT : messages.cultureSale.desiredPriceWithoutVAT}
          type="numberFloat"
          errorValues={{ incorrectMinPrice: 1000, numAfterDot: 2 }}
          className="culture-sale-request-form__desired-price"
          unitOfMeasurement="/т"
          numAfterDot={2}
          onFocus={() => gtmFocusField('desiredPrice')}
        />
      </div>
      {isCurrentRegion && !buyersAndPrices?.buyersInGivenRegionPresent ? (
        <div className="culture-sale-request-form-buyers-notice">
          <div className="culture-sale-request-form-buyers-notice__info color_pale-black text_small">
            <Icon
              className="culture-sale-request-form-buyers-notice__info-icon"
              name={EIconName.WarningFill}
              style={{ color: EColor.ORANGE }}
            />
            <span>{messages.cultureSale.topNotice.noBuyersNotice.title}</span>
          </div>
          <div className="space-holder24 space-holder32-from-md">
            {/* Кнопка жду больше покупателей */}
            <Button modifiers={['outline']} onClick={openBuyersNoticeModal} className="culture-sale-request-form__button">
              {messages.cultureSale.topNotice.noBuyersNotice.button}
            </Button>
          </div>
        </div>
      ) : (
        <div className={classnames('row', cultureName ? 'row-justify_start' : 'row-justify_end')}>
          {/* Кнопка создать заявку */}
          <Button
            type="submit"
            modifiers={cultureName ? ['green', 'small'] : ['green']}
            onClick={() => {
              form.submit()
              pushGtmEvent()
            }}
            iconProps={{ name: EIconName.Plus, className: 'culture-sale-request-form__button__icon' }}
            className="culture-sale-request-form__button"
          >
            {messages.cultureSaleRequest.createRequest}
          </Button>
        </div>
      )}
    </div>
  )
}

export default CultureSaleRequestForm
